.card-box {
  margin-bottom: 10px;
  height: 20%;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}
.card-box .card-item {
  padding: 10px;
  border-radius: 5px;
  min-width: 19%;
  justify-content: space-around;
  cursor: pointer;
}
.card-box .card-item span {
  color: #fff;
}
.card-box .card-item div {
  justify-content: space-between;
}
.card-box > :nth-child(1) {
  background-image: url(../../../assets/first.png);
  background-size: 100% 100%;
}
.card-box > :nth-child(2) {
  background-image: url(../../../assets/second.png);
  background-size: 100% 100%;
}
.card-box > :nth-child(3) {
  background-image: url(../../../assets/third.png);
  background-size: 100% 100%;
}
.card-box > :nth-child(4) {
  background-image: url(../../../assets/fourth.png);
  background-size: 100% 100%;
}
.card-box > :nth-child(5) {
  background-image: url(../../../assets/five.png);
  background-size: 100% 100%;
}
h3.title {
  padding: 0.35rem;
  border: 1px solid #ccc;
  border-bottom: 0;
}
.echerts-box {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
}
.echerts-box .btn-box {
  align-self: flex-end;
  padding: 10px;
}
.echerts-box .btn-box .el-button:focus {
  background-color: #5c6be8;
  color: #fff;
  border-color: #5c6be8;
}
.echerts-box .btn-box .active {
  background-color: #daddfa;
  color: #5c6be8;
  border-color: #daddfa;
}
.span {
  position: absolute;
  /* left: 50%; */
  /* right: 50%; */
  text-align: center;
  width: 100%;
  top: 63%;
}
